import axios from 'axios'
const headers = {"Content-Type": "application/json"};

export default {
    state: {
    },
    mutations: {

    },
    actions: {
        GET_PBI_REPORT(_, reportId) {
            return new Promise((resolve) => {
                const params = {
                    params: {
                        reportId: reportId
                    }
                };
                axios
                    .get('api/powerbi', params, headers)
                    .then( r => {
                        resolve(r);
                    });
            });
        },
        LOAD_ANALYTICS_FAVOURITE() {
            return new Promise((resolve, reject) => {
                let params = {
                    entity: "AnalyticsFavourite"
                };
                axios
                    .post("/api/uicommand/get/list", params, headers)
                    .then( r => resolve(r))
                    .catch( e => reject(e))
                    ;
            });
        },
        SAVE_ANALYTICS_FAVOURITE(_, payload) {
            return new Promise((resolve, reject) => {
                let params = {
                    entity: "AnalyticsFavourite", 
                    "data": payload
                };
                axios
                    .post("/api/uicommand/save/object", params, headers)
                    .then( r => resolve(r))
                    .catch( e => reject(e))
                    ;
            });
        },
        DELETE_ANALYTICS_FAVOURITE(_, id) {
            return new Promise((resolve, reject) => {
                let params = {
                    entity: "AnalyticsFavourite", 
                    "uuid": id
                };
                axios
                    .post("/api/uicommand/delete/object", params, headers)
                    .then( r => resolve(r))
                    .catch (e => reject(e))
                    ;
            });
        }
    },
    getters: {

    }
}