import axios from "axios";
axios.defaults.withCredentials = true
// axios.defaults.baseURL = "https://10.20.40.87";

const headers = {
  "Content-Type": "application/json",
};

export default {
  state: {},
  mutations: {},
  actions: {
    GET_PRICES() {
      return new Promise((resolve, reject) => {
        const body = {
          entity: "Statistic",
          page: 0,
          length: 15,
        }

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    GET_ALL_SUM(){
        return new Promise((resolve, reject) => {
          const body = {
            entity: "SiteInfo",
            uuid: "83900D87-0C5F-44B7-9F57-2D501EA5F350",
          }

          axios
            .post("/api/uicommand/get/object", body, headers)
            .then((res) => {
              resolve(res.data);
            })
            .catch((e) => {
              console.log(e);
              reject(e);
            })
        })
    },
    GET_TARIFFS() {
      return new Promise((resolve, reject) => {
        const body = {
          "entity":"Tariff",
          "page":0,
          "length":100,
          "filter":{"isActive":1},
          "sort":[{"field":"price"}]
        };
        axios
          .post("/api/uicommand/get/page", body, headers)
          .then(s => s.status === 200 && resolve(s.data))
          .catch(e => reject(e.response.data))
          ;
      });
    },
    SAVE_CALLBACK(_, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/uicommand/callbackrequest", payload, headers)
          .then(s => s.status === 200 && resolve(s.data))
          .catch(e => reject(e.response.data))
          ;
      });
    }
  },
  getters: {},
};
