import Vue from 'vue'
import VModal from 'vue-js-modal'
import Paginate from "vuejs-paginate"
import vSelect from 'vue-select'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import numberFilter from './filters/number.filter.js'
import titlePlugin from "@/utils/title.plugin"


import '@/assets/libs/jquery/jquery'
import '@/assets/libs/bootstrap/js/bootstrap.min.js'
import "vue-select/src/scss/vue-select.scss"

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { VueSpinners } from "@saeris/vue-spinners"
Vue.use(VueSpinners)
Vue.use(VueMeta)
Vue.use(titlePlugin)
Vue.use(VueAxios, axios)
Vue.component("v-select", vSelect)


const options = {
  position: "bottom-right",
  timeout: 3500,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
}

Vue.use(Toast, options)
Vue.use(VModal)
Vue.component("paginate", Paginate)
Vue.filter('sum', numberFilter)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

import device from "vue-device-detector"
Vue.use(device);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
