import axios from "axios"
axios.defaults.withCredentials = true

export default {
  state: {
    isAuth: false,
    role: null,
    user: {},
    tariff: {},
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    IS_AUTH(state, isAuth) {
      state.isAuth = isAuth;
    },
    LOGOUT(state) {
      state.isAuth = false;
      state.user = {};
      state.tariff = {};
    },
    SET_TARIFF(state, tariff) {
      state.tariff = tariff;
    },
    SET_ROLE(state, role) {
      state.role = role;
    }
  },
  actions: {
    LOAD_FREE_TARIFF({commit}) {
      const params = {
        entity: "Tariff",
        page: 0,
        length: 15,
        filter: {
          code: 'Tenders'
        },
        sort: [
          {"field":"createDate","by":"desc"}
        ]
      };

      return new Promise((resolve, reject) => {
        axios
            .post("/api/uicommand/get/page", params, {
              "Content-Type": "application/json",
              // withCredentials: true,
            })
            .then(result => {
                if(result.status === 200 && result.data.content.length > 0) {
                  commit("SET_TARIFF", result.data.content[0]);
                }
                resolve(result);
            })
            .catch(e => {
                reject(e);
            });
      });
    },
    LOGIN({ dispatch }, user) {
      return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("username", user.email);
      formData.append("password", user.password);

      const headers = {
        "Content-Type": "application/json",
        withCredentials: true,
      };

      axios
        .post("/login", formData, headers)
        .then((result) => {
          if (result.status === 200) {
            dispatch("IS_AUTH");
            dispatch("GET_USER");
          }
          resolve(result);
        })
        .catch((e) => {
          reject(e);
        });
      });
    },
    IS_AUTH({ commit }) {
      axios.post("/api/eoz/isAuth").then((res) => {
        if (res.status === 200) {
          commit("IS_AUTH", res.data.result);
          if (res.data.result === false) {
            commit("LOGOUT");
          }
        }
      })
      .catch(() => {
        commit("LOGOUT");
      });
    },
    GET_USER({ commit, dispatch }) {
      axios.post("/api/uicommand/get/currentUser").then((res) => {
        if (res.status === 200) {
          commit("SET_USER", res.data);
          if (res.data.userDetail && res.data.userDetail.tariff) {
            commit("SET_TARIFF", res.data.userDetail.tariff);
          }
          else {
            dispatch("LOAD_FREE_TARIFF");
          }
          dispatch("GET_COUNT");
          dispatch("GET_MESSAGES");
        }
      });
    },
    LOGOUT({ commit }) {
      axios.post("/logout").then(() => {
        commit("LOGOUT");
      });
    },
    RESET_PASSWORD(_, data) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams();
        params.append('email', data.email);
        axios.post("/passwordReset", params, 
          {
            'Content-Type': 'application/x-www-form-urlencoded'
          })
          .then(s => s.status === 200 && resolve(s.data))
          .catch(e => reject(e.response.data))
          ;
      });
    },
    REGISTRATION(_, data) {
      return new Promise((resolve, reject) => {
        const params = {
          "entity": "Registration",
          "data": data
        };
        axios.post("/api/uicommand/save/object", params, {
            "Content-Type": "application/json"
          })
          .then(s => s.status === 200 && resolve(s.data))
          .catch(e => reject(e.response.data))
          ;
      });
    },
    CHANGE_PASSWORD(_, data) {
      return new Promise((resolve, reject) => {
        const params = {
          "token":    data.token,
          "password": data.password
        };
        axios.post("/passwordReset/change", params, {
            "Content-Type": "application/json"
          })
          .then(s => s.status === 200 && resolve(s.data))
          .catch(e => reject(e.response.data))
          ;
      });
    }
  },
  getters: {
    isAuth: (state) => {
      return state.isAuth;
    },
    isSupplier: (state) => state.tariff && ['business', 'Tenders'].includes(state.tariff.code),
    userRole: (state, getters) => {
      return getters.isSupplier ? 'supplier' : state.role;
    },
    user(state) {
      return state.user;
    },
    user_tarif_name: (state) => {
      return state.user.userDetail.tariff && state.user.userDetail.tariff.nameRu;
    },
    user_tarif_id: (state) => {
      return state.user.userDetail.tariff && state.user.userDetail.tariff.id;
    },
    USER_TARIFF: state => state.tariff,
  },
};