
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            tariffs: [],
        };
    },
    created() {
        this.onCreate();
    },
    methods: {
        onCreate() {
            this.getTariffs();
        },

        getTariffs() {
            this.$store.dispatch("GET_TARIFFS")
                .then(s => this.tariffs = s.content.filter(el => el.price > 0))
                .catch(e => e.message && this.$toast.error(e.message))
                ;
        },
        checkUserDetail() {
            var userDetail = this.profile;
            var valid = userDetail && userDetail.ownershipType && userDetail.iin
            && userDetail.surname && userDetail.firstname && userDetail.orgPhoneNumber
                && (
                    (userDetail.ownershipType !== 3 && userDetail.address) 
                        || (userDetail.ownershipType === 3 && userDetail.legalAddress 
                            && userDetail.orgFullNameRu)
                    );
            return valid;
        },
        makeOrder(tariff) {
            if (this.isAuth) {
                if (this.checkUserDetail()) {
                    this.$store.dispatch("SAVE_ORDER", tariff)
                        .then(() => this.$router.push({name: "orders"}))
                        ;
                }
                else {
                    this.$router.push({name: "profile", query: { invalid: 1}});
                }
            }
            else {
                if (this.$i18n.locale == 'kz') {
                    this.$toast.info("Тарифті сатып алу үшін жүйеге кіру керек.");
                }
                else {
                    this.$toast.info("Для покупки тарифа вам необходимо авторизоваться.");
                }
            }
        },
        openUrl(url) {
            this.$router.push(url);
        },
    },
    computed: {
        ...mapGetters([
            'isAuth',
            'user',
            'PROFILE'
        ]),
        profile() {
            return this.PROFILE;
        }

    }
}