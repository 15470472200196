import axios from "axios";
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = "https://10.20.40.87";

const headers = {
  "Content-Type": "application/json",
};

export default {
  state: {
    tenderId: null,
    tenders: null,
    tenderLength: null,
  },
  mutations: {
    SET_PLAN_ID(state, id) {
      state.tenderId = id;
    },
    SET_PLANS(state, plans) {
      state.tenders = plans;
    },
    SET_PLANS_LENGTH(state, length) {
      state.tenderLength = length;
    },
  },
  actions: {
    GET_TENDER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
            commit("SET_PLANS", res.data.content);
            commit("SET_PLANS_LENGTH", res.data.totalElements);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    CLICKED_TENDER(_, id) {
      return new Promise((resolve, reject) => {
        const body = {
          entity: "Tender",
          uuid: id,
        };

        axios
          .post("/api/uicommand/get/object", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_TENDER_ID({ commit }, id) {
      commit("SET_PLAN_ID", id);
    }
  },
  getters: {
    tenderId: (state) => state.tenderId,
    TENDERS: (state) => state.tenders,
    TENDER_LENGTH: (state) => state.tenderLength,
  },
};
