<template>
   <div class="home-modal">
        <a href="" @click.prevent="show">Подробнее &nbsp;&nbsp; &#x279C;</a>
        <modal
            name="home-page-modal"
            :adaptive="true"
            :scrollable="true"
            height="auto"
            :width="1200"
        >
            <div class="modal-box">
                <p class="close" @click="hide"><i class="fas fa-times"></i></p>
                <div class="modal-box__header">
                    <div v-show="getLanguage() == 'ru'">
                        <h1 class="font-weight-bold text-left">Детальная информация о закупках Заказчиков и продажах Поставщиков на пяти торговых площадках</h1>
                        <hr>
                        <div class="row">
                            <div class="col-md-4 mb-2">
                                <h2 class="font-weight-bold text-left h-25">Узнай своего покупателя</h2>
                                <p align="justify">Найдите надежных заказчиков с большим бюджетом и стабильными заказами. Просматривайте его актуальные и завершенные договоры, а также Планы закупа. Узнайте цены в его контрактах. Изучите общую порядочность заказчика изучив его технические спецификации и отмененные закупки.</p>
                            </div>
                            <div class="col-md-4 mb-2">
                                <h2 class="font-weight-bold text-left h-25">Найди своего конкурента/партнера</h2>
                                <p align="justify">Узнайте, является ли поставщик вашим конкурентом в предстоящей закупке. Оцените соотношение участий и побед поставщика, изучите ценовую политику. Изучите сильные и слабые стороны конкурента, чтобы предугадать его действия и заранее продумать свою политику участий.</p>
                            </div>
                            <div class="col-md-4 mb-2">
                                <h2 class="font-weight-bold text-left h-25">Анализируй риски</h2>
                                <p align="justify">Анализ связей между заказчиком и поставщиком. Вся статистика по совместным договорам и наличие благосклонности.</p>
                            </div>
                        </div>
                    </div>
                    <div v-show="getLanguage() == 'kz'">
                        <h1 class="font-weight-bold text-left">Бес сауда алаңында тапсырыс берушілердің сатып алу және жеткізушілердің сатылымдары туралы толық акпарат</h1>
                        <hr>
                        <div class="row">
                            <div class="col-md-4 mb-2">
                                <h2 class="font-weight-bold text-left h-25">Тұтынушынынды тану біл</h2>
                                <p align="justify">Бюджеті үлкен, тапсырыстары тұракты сенімді тапсырыс берушіні тауып алыңыз. Оның өзекті және аяқталған келісім-шарттарын, сатып алу жоспарын қарап отырыңыз. Шартындағы бағаларды біліңіз. Техникалық сипаттамасын және күшін жойған сатып алуларын зерттеп, тапсырыс берушінің жалпы адалдығын біліңіз. </p>
                            </div>
                            <div class="col-md-4 mb-2">
                                <h2 class="font-weight-bold text-left h-25">Бәсеклесінді/әріптесінді тану біл</h2>
                                <p align="justify">Алда келеген сатып алуда жеткізуші сіздің бәсекелесініз бола ма екенің біліп алыңыз. Жеткізушінің қатысу мен жеңістерінің ара қатынасын бағалаңыз. Өзіңіздің қатысу саясатыңызды мұқият жоспарлау үшін бәсекелесініздің әрекеттерін алдын ала болжап,  күшті және әлсіз тұстарың талдаңыз.</p>
                            </div>
                            <div class="col-md-4 mb-2">
                                <h2 class="font-weight-bold text-left h-25">Қатерлерді талдаңыз</h2>
                                <p align="justify">Тапсырыс беруші мен жеткізушінің ара қатынасын талдаңыз. Ортақ келісім-шарттартдың толық статистикасы және игі ниеттіліктің бар болуы.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        name: 'home-page-modal',
        methods: {
            show() {
                this.$modal.show('home-page-modal')
            },
            hide() {
                this.$modal.hide('home-page-modal')
            },
            getLanguage() {
                return this.$i18n.locale;
            }
        }
    }
</script>

<style lang="scss" scoped>

.modal-box {
    // background: #999;
    width: 100%;
    height: 100%;
    padding: 50px;
}
.home-modal p {
    display: block;
}
</style>