import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/!/*",
    redirect: "/*",
  },
  {
    path: "/starter",
    name: "starter",
    component: () => import("../views/StarterTemplate.vue"),
  },
  {
    path: "/packages",
    name: "packages",
    component: () => import("../views/Packages.vue"),
  },
  {
    path: "/analytics",
    name: "analytics",
    component: () => import("../views/Analytics"),
    props: true,
  },
  {
    path: "/analytic-apk",
    name: "analytic-apk",
    component: () => import("../views/ForApk"),
    props: true
  },
  {
    path: "/analytics/:id",
    name: "analytic",
    component: () => import("../views/AnalyticsItem"),
    props: true
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/reestr",
    name: "reestr",
    component: () => import("../views/Reestr.vue"),
  },
  {
    path: "/platforms",
    name: "platforms",
    component: () => import("../views/Platforms"),
  },
  {
    path: "/purchases",
    redirect: { name: "plans" },
    component: () => import("../views/Purchase.vue"),
    children: [
      {
        path: "/plans/:id?",
        alias: "/plan/:id?",
        name: "plans",
        component: () => import("../views/purchasePages/Plans"),
        props: (route) => ({ keywords: route.query.keyword }),
      },
      {
        path: "/tenders/:id?",
        alias: "/tender/:id?",
        name: "tenders",
        component: () => import("../views/purchasePages/Tender"),
        props: (route) => ({ keywords: route.query.keyword }),
      },
      {
        path: "/lots/:id?",
        alias: "/lot/:id?",
        name: "lots",
        component: () => import("../views/purchasePages/Lots"),
        props: (route) => ({ keywords: route.query.keyword }),
      },
      {
        path: "/contracts/:id?",
        alias: "/contract/:id?",
        name: "contracts",
        component: () => import("../views/purchasePages/Contracts"),
        props: (route) => ({ keywords: route.query.keyword }),
      },
      {
        path: "/favourite",
        name: "favourite",
        meta: { auth: true },
        component: () => import("../views/purchasePages/Favourite"),
      },
    ],
  },
  {
    path: "/information",
    redirect: { name: "documents" },
    component: () => import("../views/Inform.vue"),
    children: [
      {
        path: "documents",
        name: "documents",
        component: () => import("../views/informPage/InformDocs"),
      },
      {
        path: "videos",
        name: "videos",
        component: () => import("../views/informPage/InformVideos"),
      },
      {
        path: "trading",
        name: "trading",
        component: () => import("../views/informPage/InformTrading"),
      },
      {
        path: "user-agreement",
        name: "user-agreement",
        component: () => import("../views/informPage/UserAgreement"),
      },
    ],
  },
  {
    path: "/video",
    name: "video",
    redirect: { name: "video-general" },
    component: () => import("../views/VideoTutorials.vue"),
    children: [
      {
        path: "video-general",
        name: "video-general",
        component: () => import("../views/informPage/InformVideos"),
      },
      {
        path: "video-supplier",
        name: "video-supplier",
        component: () => import("../views/informPage/InformVideos"),
      },
    ],
  },
  {
    path: "/user",
    redirect: { name: "profile" },
    meta: { auth: true },
    component: () => import("@/pages/AccountPage"),
    children: [
      {
        path: "profile",
        name: "profile",
        meta: { auth: true },
        component: () => import("@/pages/AccountPage/UserProfile"),
      },
      {
        path: "procurement",
        name: "procurement",
        component: () => import("@/pages/AccountPage/ProcurementNotices"),
      },
      {
        path: "notifications/:id?",
        name: "notifications",
        component: () => import("@/pages/AccountPage/AccountNotifications"),
      },
      {
        path: "appeals",
        name: "appeals",
        component: () => import("@/pages/AccountPage/AccountAppeals"),
      },
      {
        path: "orders",
        name: "orders",
        component: () => import("@/pages/AccountPage/AccountOrders"),
      },
      {
        path: "invitations",
        name: "invitations",
        component: () => import("@/pages/AccountPage/AccountInvitations"),
      },
      {
        path: "documents",
        name: "account-documents",
        component: () => import("@/pages/AccountPage/AccountDocuments"),
      },
      {
        path: "search-templates",
        name: "search-templates",
        component: () => import("@/pages/AccountPage/SearchTemplates"),
      },
    ],
  },
  {
    path: "/invitation/:id",
    name: "invitation",
    component: () => import("@/pages/Backlink/Invitation"),
  },
  {
    path: "/registration/:id",
    name: "registration",
    component: () => import("@/pages/Backlink/ActivateRegistration"),
  },
  {
    path: "/registration/invitation/:id/:email?",
    name: "invitation-with-registration",
    component: () => import("@/pages/Backlink/InvitationRegistration"),
  },
  {
    path: "/passwordReset/hash/:token",
    name: "change-password",
    component: () => import("@/pages/Backlink/ChangePassword"),
  },
  {
    path: "/region",
    name: "region",
    component: () => import("../pages/RegionDashboard"),
  },
  {
    path: "/monopoly",
    name: "monopoly",
    component: () => import("../views/MonopolyPage"),
  },
  {
    path: "/tbpage",
    name: "tbpage",
    component: () => import("../views/TBPage"),
  },
  {
    path: "/stimul",
    name: "stimul",
    component: () => import("../views/StimulPage"),
  },
  {
    path: "*",
    name: "notFound",
    component: () => import("../views/Error.vue"),
  },
];

const router = new VueRouter({
  routes,
  // mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth);

  if (requireAuth && !store.getters.isAuth) {
    next("/");
    router.app.$toast.error(router.app.$t("message.isNotAuth"));
  } else {
    next();
  }
});


export default router
