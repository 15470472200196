import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import createPersistedState from "vuex-persistedstate"

import auth from './auth'
import plans from "./plans"
import tenders from "./tender"
import lots from "./lots"
import home from "./home"
import notifications from './notifications'
import account from './account'
import analytics from './analytics'

Vue.use(Vuex)
axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'https://10.10.101.32';
// axios.defaults.baseURL = 'https://eoz.kz';



export default new Vuex.Store({
  state: {
    language: "ru",
  },
  mutations: {
    GET_LANGUAGE(state, lang) {
      state.language = lang;
    },
    SET_LANGUAGE(state, lang) {
      state.language = lang;
    },
  },
  actions: {
    GET_LANGUAGE({ commit }) {
      axios
        .post("/api/uicommand/get/language")
        .then((response) => {
          commit("GET_LANGUAGE", response.data.code);
        })
        .catch((e) => {
          return console.log(e);
        });
    },
    SET_LANGUAGE({ commit }, lang) {
      axios
        .get(`/api/uicommand/change/language?language=${lang}`)
        .then((res) => {
          commit("SET_LANGUAGE", res.data.code);
        });
    },
  },
  getters: {
    LANG: (state) => state.language,
  },
  modules: {
    auth,
    plans,
    notifications,
    home,
    tenders,
    lots,
    account,
    analytics
  },
  plugins: [createPersistedState({
    paths: ['auth', 'notifications']
  })],
});
