import axios from 'axios'
axios.defaults.withCredentials = true
// axios.defaults.baseURL = "https://10.20.40.87";

const headers = {
  "Content-Type": "application/json",
}



export default {
  state: {
    planId: null,
    plans: null,
    plansLength: null,
    cities: null,
    opened_cities: [],
    cliked_city: null,
  },
  mutations: {
    SET_PLAN_ID(state, id) {
      state.planId = id;
    },
    SET_PLANS(state, plans) {
      state.plans = plans;
    },
    SET_PLANS_LENGTH(state, length) {
      state.plansLength = length;
    },
    SET_CITIES(state, data) {
      state.cities = data;
    },
    SET_CITIES_CHILD(state, data) {
      state.cities = [...state.cities, ...data];
    },
    SET_OPENED_CITIES(state, id) {
      state.opened_cities.push(id);
    },
    RESET_OPENED_CITIES(state) {
      state.opened_cities = [];
    },
    SET_CLICKED_CITY(state, data) {
      state.cliked_city = data;
    },
    RESET_CLICKED_CITY(state) {
      state.cliked_city = null;
    },
  },
  actions: {
    GET_PLANS({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
            commit("SET_PLANS", res.data.content);
            commit("SET_PLANS_LENGTH", res.data.totalElements);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    CLICKED_PLAN(_, id) {
      return new Promise((resolve, reject) => {
        const body = {
          entity: "Plan",
          uuid: id,
        };

        axios
          .post("/api/uicommand/get/object", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_PLAN_ID({ commit }, id) {
      commit("SET_PLAN_ID", id);
    },
    GET_TRU(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_PURCHASE_AREA(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_METHOD_TRADE(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_MONTH(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_STATUS(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    GET_CITIES({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            commit("SET_CITIES", res.data.content);
            // console.log(res.data.content);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_CITIES_CHILDREN({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            commit("SET_CITIES_CHILD", res.data.content);
            // console.log(res.data.content);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
  getters: {
    planId: (state) => state.planId,
    PLANS: (state) => state.plans,
    PLANS_LENGTH: (state) => state.plansLength,
    TRU: (state) => state.trues,
    CITIES: (state) => state.cities,
    get_city_by_parent: (state) => (id) => {
      // console.log(state, id);
      return state.cities
        ? state.cities.filter((a) => (a.parentId ? a.parentId : 0) === id) : [];
    },
    get_cato: (state) => state.cliked_city,
  },
};