<template>
    <div class="contact__us">
        <a href="" @click.prevent="show">{{$t('navigation.feedback')}}</a>
        <modal name="modal-feedback"  
            :adaptive="true"
            :scrollable="true"
            height="auto"
            width="700"
        >
            <div class="modal-area">
                <div class="modal__contact">
                    <button type="button" class="close text-white" aria-label="Close"
                        @click="hide"
                        >
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <h5 class="text-center">{{ $t('appeal.create_appeal') }}</h5>


                    <ul class="nav nav-pills mb-3 d-flex" id="pills-tab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
                                aria-controls="pills-home" aria-selected="true">{{ $t('default.expert_appeals') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                                aria-controls="pills-profile" aria-selected="false">{{ $t('default.compliance') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab"
                                aria-controls="pills-contact" aria-selected="false">{{ $t('default.offers') }}</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <form @submit.prevent="onSubmitAppeal">
                            <div class="row">
                                <div class="col-12 col-sm-6">
<!--                                     
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.appeal.appealType"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.appealType')"
                                        :options="appealType['appeal']">
                                    </v-select>  
-->

                                    <label>{{$t('appeal.appealType')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.appeal.appealType"
                                    required
                                    >
                                    <option v-for="t in appealType['appeal']" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
<!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.appeal.appealObject"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.appealObject')"
                                        :options="appealObject['appeal']">
                                    </v-select> 
-->
                                    <label>{{$t('appeal.appealObject')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.appeal.appealObject"
                                    required
                                    >
                                    <option v-for="t in appealObject['appeal']" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
<!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.appeal.system"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.system')"
                                        :options="systems">
                                    </v-select> 
-->
                                    <label>{{$t('appeal.system')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.appeal.system"
                                    required
                                    >
                                    <option v-for="t in systems" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Email" v-model="feedbackOne.appeal.email">
                                </div>
                                <div class="col-12">
                                    <textarea 
                                        class="form-control" 
                                        name="message" id="appeal_message"
                                        :placeholder="$t('appeal.message')" 
                                        v-model="feedbackOne.appeal.question" rows="10">
                                    </textarea>
                                </div>
                            </div>
                            <div class="row modal__flex">
                                <div>
                                    <input type="checkbox" 
                                        v-model="feedbackOne.appeal.isAnonymous" 
                                        id="checkboxAnonymous1"
                                        >
                                    <label for="checkboxAnonymous1" style="font-size: 16px;">{{ $t('appeal.isAnonymous') }}</label>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-lg btn-zhako" :disabled="$v.feedbackOne.appeal.$invalid">{{ $t('appeal.send') }}</button>
                                </div>
                            </div>
                        </form>
                        </div>
                        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                        <form @submit.prevent="onSubmitComplaint">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <!-- 
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.complaint.appealType"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.appealType')"
                                        :options="appealType['complaint']">
                                    </v-select>
                                    -->
                                    <label>{{$t('appeal.appealType')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.complaint.appealType"
                                    required
                                    >
                                    <option v-for="t in appealType['complaint']" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.complaint.appealObject"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.appealSubject')"
                                        :options="appealObject['complaint']">
                                    </v-select> 
                                    -->
                                    <label>{{$t('appeal.appealSubject')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.complaint.appealObject"
                                    required
                                    >
                                    <option v-for="t in appealObject['complaint']" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.complaint.system"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.system')"
                                        :options="systems">
                                    </v-select> 
                                    -->
                                    <label>{{$t('appeal.system')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.complaint.system"
                                    required
                                    >
                                    <option v-for="t in systems" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{$t('appeal.tenderNumber')}}</label>
                                    <input type="text" class="form-control" 
                                        v-model="feedbackOne.complaint.tenderNumber"
                                        :placeholder="$t('appeal.tenderNumber')"
                                        >
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{$t('appeal.tenderDate')}}</label>
                                    <input type="text" class="form-control" 
                                        v-model="feedbackOne.complaint.tenderDate"
                                        :placeholder="$t('appeal.tenderDate')"
                                        >
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Email" v-model="feedbackOne.complaint.email">
                                </div>
                                <div class="col-12">
                                    <textarea class="form-control" name="message" id="complaint_message" rows="10" 
                                        :placeholder="$t('appeal.message')"
                                        v-model="feedbackOne.complaint.question"
                                        ></textarea>
                                </div>
                            </div>
                            <div class="row modal__flex">
                                <div>
                                    <input type="checkbox" 
                                        v-model="feedbackOne.complaint.isAnonymous" 
                                        id="checkboxAnonymous2"
                                        >
                                    <label for="checkboxAnonymous2" style="font-size: 16px;">{{ $t('appeal.isAnonymous') }}</label>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-lg btn-zhako" :disabled="$v.feedbackOne.complaint.$invalid">{{ $t('appeal.send') }}</button>
                                </div>
                            </div>
                        </form>
                        </div>
                        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                        <form @submit.prevent="onSubmitOffer">
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.offer.appealType"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.offerTheme')"
                                        :options="appealType['offer']">
                                    </v-select> 
                                    -->
                                    <label>{{$t('appeal.offerTheme')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.offer.appealType"
                                    required
                                    >
                                    <option v-for="t in appealType['offer']" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.offer.appealObject"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.appealObject')"
                                        :options="appealObject['offer']">
                                    </v-select> 
                                    -->
                                    <label>{{$t('appeal.appealObject')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.offer.appealObject"
                                    required
                                    >
                                    <option v-for="t in appealObject['offer']" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <!--
                                    <v-select 
                                        class="style-chooser"
                                        :label="getFieldName('name')"
                                        v-model="feedbackOne.offer.system"
                                        :reduce="prefix => prefix"
                                        :placeholder="$t('appeal.system')"
                                        :options="systems">
                                    </v-select> 
                                    -->
                                    <label>{{$t('appeal.system')}}</label>
                                    <select
                                    class="style-chooser form-control"
                                    v-model="feedbackOne.offer.system"
                                    required
                                    >
                                    <option v-for="t in systems" :value="t" :key="t.id"> {{ getNameLocale(t) }}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>Email</label>
                                    <input type="email" class="form-control" placeholder="Email" v-model="feedbackOne.offer.email">
                                </div>
                                <div class="col-12">
                                    <textarea class="form-control" name="message" id="offer_message" rows="10" 
                                        :placeholder="$t('appeal.message')"
                                        v-model="feedbackOne.offer.question"
                                        ></textarea>
                                </div>
                            </div>
                            <div class="row modal__flex">
                                <div>
                                    <input type="checkbox" 
                                        v-model="feedbackOne.offer.isAnonymous" 
                                        id="checkboxAnonymous3"
                                        >
                                    <label for="checkboxAnonymous3" style="font-size: 16px;">{{ $t('appeal.isAnonymous') }}</label>
                                </div>
                                <div>
                                    <button type="submit" class="btn btn-lg btn-zhako" :disabled="$v.feedbackOne.offer.$invalid">{{ $t('appeal.send') }}</button>
                                </div>
                            </div>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

import { required, email } from "vuelidate/lib/validators";

    export default {
        name: 'modal-feedback',
        data: () => ({
            feedbackOne: {
                appeal: {
                    appealType: null,
                    appealObject: null,
                    system: null,
                    email: null,
                    question: null,
                    isAnonymous: null
                },
                complaint: {
                    appealType: null,
                    appealObject: null,
                    system: null,
                    email: null,
                    question: null,
                    tenderDate:null,
                    tenderNumber:null,
                    isAnonymous: null
                },
                offer: {
                    appealType: null,
                    appealObject: null,
                    system: null,
                    email: null,
                    question: null,
                    isAnonymous: null
                },
            },
            appealType: {
                appeal: [],
                complaint: [],
                offer: []
            },
            appealObject: {
                appeal: [],
                complaint: [],
                offer: []
            },
            systems: []
        }),
        validations: {
            feedbackOne: {
                appeal: {
                    appealType: { required },
                    appealObject: { required },
                    system: { required },
                    email: { required, email },
                    question: { required }
                },
                complaint: {
                    appealType: { required },
                    appealObject: { required },
                    system: { required },
                    email: { required, email },
                    question: { required },
                    tenderDate: { required },
                    tenderNumber: { required }
                },
                offer: {
                    appealType: { required },
                    appealObject: { required },
                    system: { required },
                    email: { required, email },
                    question: { required }
                },
            }
        },
        mounted() {
            this.onCreate();
        },
        methods: {
            getSystems() {
                let params = {
                    entity:"DictionaryItem",
                    page:0,
                    length:500,
                    filter: {
                        "dictionaryId.code": "System"
                    }
                }

                this.$store.dispatch('GET_LIST', params)
                    .then((res) => res.status === 200 && (this.systems = res.data.content))
                    ;
            },
            getType(code) {
                
                let params = {
                    entity:"DictionaryItem",
                    page:0,
                    length:500,
                    filter: {
                        "dictionaryId.code":"AppealType",
                        code:code
                    }
                };

                this.$store.dispatch('GET_LIST', params)
                    .then((res) => res.status === 200 && (this.appealType[code] = res.data.content))
                    ;
            },
            getObject(code) {
                let params = {
                    entity:"DictionaryItem",
                    page:0,
                    length:500,
                    filter: {
                        "dictionaryId.code": "AppealObject",
                        code: code
                    }
                };

                this.$store.dispatch('GET_LIST', params)
                    .then((res) => res.status === 200 && (this.appealObject[code] = res.data.content))
                    ;
            },
            saveAppeal(code) {
                this.feedbackOne[code].isAnonymous = this.feedbackOne[code].isAnonymous ? 1 : 0;
                this.$store.dispatch("SAVE_APPEAL", this.feedbackOne[code])
                    .then(s => {
                        if (s.id) {
                            this.$toast.success("Успешно отправлено"); // "Сәтті өңделді"
                            this.feedbackOne[code] = {};
                            this.hide();
                        }
                    })
                    .catch(e => e.message && this.$toast.error(e.message))
                    ;
            },

            getFieldName(name) {
                let value;
                switch(this.$i18n.locale) {
                    case 'kz':
                        value = name+'Kz';
                        break;
                    default:
                        value = name+'Ru';
                }
                return value;
            },
            getNameLocale(t) {
                let value = '';
                switch(this.$i18n.local) {
                    case 'kz':
                        value = t.nameKz;
                        break;
                    default:
                        value = t.nameRu;
                }
                return value;
            },
            show() {
                this.$modal.show('modal-feedback')
            },
            hide() {
                this.$modal.hide('modal-feedback')
            },

            onCreate() {
                this.getSystems();

                this.getType('appeal');
                this.getObject('appeal');

                this.getType('complaint');
                this.getObject('complaint');

                this.getType('offer');
                this.getObject('offer');
            },
            onSubmitAppeal() {
                this.saveAppeal('appeal');
            },
            onSubmitComplaint() {
                this.saveAppeal('complaint');
            },
            onSubmitOffer() {
                this.saveAppeal('offer');
            }
        }
    }
</script>

<style lang="scss" scoped>
.close {
    color: #fff;
    font-weight: 300;
}

.tab-content ul {
    display: block !important;
}
.vs--single span.vs__selected{
    color: #333 !important;
}
</style>