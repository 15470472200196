import axios from 'axios'
const headers = {"Content-Type": "application/json"};

export default {
    state: {
        profile: {
            firstname: '',
            lastname: '',
        },

        notifications: [],
        notifications_page_count: 0,
        notifications_payload: {
            entity:"Notification",
            page:0,
            length:15,
            sort:[
                {"field":"createDate","by":"desc"}
            ]
        },

        selectedNotification: null,

        procurement_codes: [],
        procurement_page_count: 0,
        procurement_payload: {
            entity: "MyEnstru",
            page: 0,
            length: 15,
            sort: [
                {"field":"entryDate","by":"desc"}
            ]
        },
    },
    mutations: {
        NOP() {
            // no operation
        },
        SET_NOTIFICATIONS(state, notifications) {
            state.notifications = notifications;
        },
        SET_NOTIFICATIONS_CURRENT_PAGE(state, page) {
            state.notifications_payload.page = page - 1;
        },
        SET_NOTIFICATIONS_PAGE_COUNT(state, count) {
            state.notifications_page_count = count;
        },

        SELECT_NOTIFICATION(state, notification) {
            state.selectedNotification = notification;
        },

        SET_PROFILE(state, user) {
            state.profile = user;
        },

        SET_PROCUREMENT_CODES(state, codes) {
            state.procurement_codes = codes;
        },
        SET_PROCUREMENT_CURRENT_PAGE(state, page) {
            state.procurement_payload.page = page - 1;
        },
        SET_PROCUREMENT_PAGE_COUNT(state, count) {
            state.procurement_page_count = count;
        },
    },
    actions: {
        LOAD_PROFILE({commit}) {
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/get/object", {"entity": "UserDetail"}, headers)
                    .then(result => {
                        resolve(result.data);
                        commit("SET_PROFILE", result.data);
                    })
                    ;
            });
        },
        SAVE_PROFILE({dispatch}, data) {
            const body = {"entity": "UserDetail", "data": data};
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(result => {
                        resolve(result.data);
                        dispatch("LOAD_PROFILE");
                    });
            });
        },
        LOAD_NOTIFICATIONS({commit, state }, data) {
            const body = { 
                ...state.notifications_payload, 
                filter: { type: data } 
            };

            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/get/page", body, headers)
                    .then(result => {
                        resolve(result);

                        commit('SET_NOTIFICATIONS', result.data.content);
                        commit('SET_NOTIFICATIONS_CURRENT_PAGE', result.data.number + 1);
                        commit('SET_NOTIFICATIONS_PAGE_COUNT', result.data.totalPages);
                    })
                    .catch(e => {
                        console.log(e);
                        reject(e);
                    });
            });
        },
        LOAD_NOTIFICATION({commit}, payload) {
            const body = {"entity":"Notification","uuid":payload};
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/get/object", body, headers)
                    .then(result => {
                        commit("SELECT_NOTIFICATION", result.data);
                        result.status === 200 && resolve(result.data);
                    })
                    ;

            })
        },
        SAVE_NOTIFICATION(_, payload) {
            const body = {
                "entity":"Notification",
                "data": payload
            };
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(result => result.status === 200 && resolve(result))
                    ;

            });
        },

        LOAD_PROCUREMENT_CODES({commit, state}) {
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/get/page", state.procurement_payload, headers)
                    .then(result => {
                        resolve(result);

                        commit('SET_PROCUREMENT_CODES', result.data.content);
                        commit('SET_PROCUREMENT_CURRENT_PAGE', result.data.number + 1);
                        commit('SET_PROCUREMENT_PAGE_COUNT', result.data.totalPages);
                    })
                    ;
            });
        },
        LOAD_ENSTRU15_CODES({commit}, payload) {
            const request_payload = Object.assign({
                entity: "Enstru15",
                page: 0,
                length: 50
            }, payload);
            return new Promise((resolve, reject) => {
                commit('NOP');
                axios
                    .post("/api/uicommand/get/page", request_payload, headers)
                    .then(result => {
                        resolve(result);
                    })
                    .catch(e => reject(e))
                    ;
            });
        },
        ADD_PROCUREMENT_CODE({commit}, payload) {
            const body = {
                entity:"MyEnstru",
                data:Object.assign({
                    enstru15Id:null,
                    enstruList:[
                    ]
                }, payload)
            };
            return new Promise((resolve) => {
                commit("NOP");
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(result => resolve(result))
                    ;
            });
        },
        DELETE_PROCUREMENT_CODE({commit}, payload) {
            const body = {
                entity: "MyEnstru",
                uuid: payload.id
            }
            return new Promise((resolve) => {
                commit("NOP");
                axios
                    .post("/api/uicommand/delete/object", body, headers)
                    .then(result => resolve(result))
                    ;
            });
            
        },

        LOAD_ORDERS({commit}, payload) {
            const body = Object.assign({
                entity:"Orders",
                page:0,
                length:15,
                sort:
                [
                    {"field":"entryDate","by":"desc"}
                ]
            }, payload);
            return new Promise((resolve) => {
                commit("NOP");
                axios
                    .post("/api/uicommand/get/page", body, headers)
                    .then(result => resolve(result))
                    ;
            })
        },
        LOAD_ORDER_SIGN({commit}, payload) {
            return new Promise((resolve) => {
                commit("NOP");
                axios
                    .get("api/plan/paymentSign?orderId=" + payload)
                    .then(result => resolve(result))
                    ;
            });
        },
        LOAD_DISCOUNT({commit}) {
            const body = {"entity":"Discount"};
            return new Promise((resolve) => {
                commit("NOP");
                axios
                    .post("/api/uicommand/get/object", body, headers)
                    .then(result => {
                        resolve(result);
                    })
                    ;

            })
        },
        SAVE_ORDER(_, tariff) {
            return new Promise((resolve, reject) => {
                const body = {
                    "entity": "Orders",
                    "data": {
                        "tariffId": tariff,
                        "amount": tariff.price
                    }
                };
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(s => s.status === 200 && resolve(s.data))
                    .catch(e => reject(e.response.data))
                    ;
            });
        },

        SAVE_APPEAL(_, data) {
            
            const body = {"entity": "Appeal", "data": data};

            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(result => result.status === 200 && resolve(result.data))
                    .catch(e => reject(e.response.data))
                    ;
            });
        },
        LOAD_MYAPPEALS({commit}, payload) {
            const body = Object.assign({
                entity: "MyAppeals",
                page:   0,
                length: 15,
                filter: {},
                sort:   []
            }, payload);
            return new Promise((resolve) => {
                commit("NOP");
                axios
                    .post("/api/uicommand/get/page", body, headers)
                    .then(r => r.status === 200 && resolve(r.data))
                    ;
            });
        },

        LOAD_INVITATIONS(_, payload) {
            const body = {
                "entity": "UserRelations",
                "page":   payload.page,
                "length": payload.length,
                "filter": payload.filter
            };
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/get/page", body, headers)
                    .then(r => r.status === 200 && resolve(r.data))
                    ;
            });
        },
        DELETE_INVITATION(_, payload) {
            const body = {
                "entity": "UserRelations",
                "uuid":   payload
            };
            return new Promise((resolve) => {
                axios
                    .post("/api/uicommand/delete/object", body, headers)
                    .then(r => r.status === 200 && resolve(r))
                    ;
            });
        },
        SAVE_INVITATION(_, payload) {
            const body = {
                "entity": "UserRelations",
                "data":   payload
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(r => r.status === 200 && resolve(r))
                    .catch(e => reject(e.response))
                    ;
            });
        },

        UPLOAD_DOCUMENT(_, payload) {
            let onUploadProgress = payload.callback && payload.callback;
            let data = new FormData();
            data.append("file", payload.file);
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/upload/file", data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        },
                        onUploadProgress
                    })
                    .then(r => resolve(r))
                    .catch(e => reject(e))
                    ;
            });
        },
        SAVE_DOCUMENT(_, payload) {
            let body = {
                entity: "UserFile",
                data: {
                    fileList: payload.files
                }
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/save/object", body, headers)
                    .then(s => resolve(s))
                    .catch(e => reject(e))
                    ;
            });
        },
        LOAD_DOCUMENTS() {
            let body = {
                entity: "UserFile"
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/get/list", body, headers)
                    .then(s => resolve(s))
                    .catch(e => reject(e))
                    ;
            });
        },
        DELETE_DOCUMENT(_, id) {
            let body = {
                entity: "UserFile",
                uuid: id
            };
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/uicommand/delete/object", body, headers)
                    .then(s => resolve(s))
                    .catch(e => reject(e))
                    ;
            });
        },
        DELETE_FILE(_, id) {
            let body = {
                entity: "FileDescription",
                uuid: id
            };
            return axios
                .post("/api/uicommand/delete/object", body, headers)
                ;
        }
        
    },
    getters: {
        PROFILE: state => state.profile,

        NOTIFICATION: state => state.selectedNotification,
        NOTIFICATIONS: state => state.notifications,
        NOTIFICATIONS_CURRENT_PAGE: state => state.notifications_payload.page + 1,
        NOTIFICATIONS_PAGE_COUNT: state => state.notifications_page_count,
        NOTIFICATIONS_PAYLOAD: state => state.notifications_payload,
        NOTIFICATIONS_STARTWITH: state => state.notifications_payload.page * state.notifications_payload.length + 1,

        PROCUREMENT_CODES: state => state.procurement_codes,
        PROCUREMENT_CURRENT_PAGE: state => state.procurement_payload.page + 1,
        PROCUREMENT_PAGE_COUNT: state => state.procurement_page_count,
        PROCUREMENT_STARTWITH: state => state.procurement_payload.page * state.procurement_payload.length + 1,
    }
}