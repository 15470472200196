<template>
    <div class="message-box" >
        <div class="message-box__header box-header">
            <div class="box-header__text">
                {{ $t("navigation.notification") }}
            </div>
            <div class="box-header__count">
                {{count}} {{ $t("navigation.new_notification") }}
            </div>
            
        </div>
        <div class="message-box__content box-content">
            <div 
                class="box-content__item"
                v-for="item in messages"   
                :key="item.id" 
                @click="showNotification(item.id)"
            >
                <div class="box-content__icon">
                    <i class="far fa-envelope"></i>
                </div>
                <div class="box-content__text">
                    <p> {{item.subject}} </p>
                    <p> {{item.createDate}} </p>
                </div>
            </div>
        </div>
        <div class="message-box__footer box-footer">
            <router-link to="/user/notifications" class="box-footer__text">{{ $t("navigation.all_notification") }}</router-link>
        </div>
    </div>
</template>

<script>

    export default {
        props: ['count', 'messages'],
        name: 'message-box',
        methods: {
            showNotification(id) {
                this.$router.push({ name: 'notifications', params: {id:id}});
            }
        },
        computed: { }
    }
</script>

<style lang="scss" scoped>
.message-box{
    border: 1px solid #ccc;
    width: 300px;
    border-radius: 5px;
    z-index: 5;
    background: rgb(248, 248, 248);
    // background: #fff;
    position: absolute;
    top: 51px;
    left: -140px;
}
.box-header {
    padding: 10px 20px ;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__count {
        padding: 2px 4px;
        background: #FFAD00;
        color: #fff;
        border-radius: 5px;
    }
}
.box-content {
    transition: .3s;
    height: 300px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 7px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        display: none;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #a8a8a8; 
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #9c9c9c; 
    }
    &__item {
        padding: 10px 20px;
        display: flex;
        border-bottom: 1px solid rgb(238, 238, 238);
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        &:hover {
            background: #fff;
        }
        &:last-child{
            border-bottom: 0;
        }
    }
    &__icon {
        width: 30px;
        height: 30px;
        color: #FFAD00;
        font-size: 18px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
    }
    &__text {
        margin-left: 20px;
        p {
            &:first-child{
                font-weight: 500;
            }
            &:last-child {
                color: #777;
                font-size: 12px;
            }
            margin-bottom: 0;
        }
    }
}
.box-footer {
    padding: 10px 20px;
    border-top: 1px solid #ccc;
    &__text{
        color: #333 !important;
        display: block;
        text-align: center;
    }
    &:hover {
        background: #fff;
    }
}
</style>