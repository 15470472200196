import axios from "axios";
axios.defaults.withCredentials = true
// axios.defaults.baseURL = "https://10.20.40.87";

const headers = {
  "Content-Type": "application/json;charset=UTF-8",
};

export default {
  state: {},
  mutations: {},
  actions: {
    GET_LIST(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/page", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    CLICKED_LIST(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/object", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    GET_ITEMS(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/get/list", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    CHOOSE_FAVOURITE(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/save/object", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    DELETE_FAVOURITE(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/delete/object", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    },
    ADD_FAVOURITE(_, payload) {
      return new Promise((resolve, reject) => {
        const body = { ...payload };

        axios
          .post("/api/uicommand/save/object", body, headers)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log(e);
            reject(e);
          });
      });
    }
  },
  getters: {},
};
