<template>
    <div class="callback-button dropleft">
        <button type="button" class="btn btn-zhako btn-circle btn-xl" data-toggle="modal" data-target="#callbackModal" @click="show">
            <i class="fas fa-phone fa-spin"></i>
        </button>


        <modal
            name="ss-callback-modal"
            :adaptive="true"
            :scrollable="true"
            height="auto"
            width="500"
            >
            <div class="container">
                <button type="button" class="close text-white" aria-label="Close"
                    @click="hide"
                    >
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="pt-3 pb-3 pl-3 pr-3">

                <form @submit.prevent="onSubmitCallback" v-if="screen === 'callback'" autocomplete="off">
                    <div class="form-group">
                        <p class="h5 text-center">{{ $t('CallbackModal.request_callback') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="inputrequest1">{{ $t('CallbackModal.select_request_type') }}</label>
                        <select class="form-control" id="inputrequest1" v-model="callback.message">
                            <option> {{ $t("CallbackModal.type_working_with_portal") }}</option>
                            <option> {{ $t("CallbackModal.type_connection_tariff_package") }}</option>
                            <option> {{ $t("CallbackModal.type_conclusion_contracts") }}</option>
                        </select>
                        <template v-if="$v.callback.message.$error">
                        <small class="form-text text-warning" v-if="!$v.callback.message.required">{{ $t('CommonForm.field_is_required') }}</small>
                        </template>
                    </div>
                    <div class="form-group">
                        <label for="inputusername1">{{ $t('CallbackModal.input_name') }}</label>
                        <input type="username" class="form-control" name="username" id="inputusername1" aria-describedby="usernameHelp" :placeholder="$t('CallbackModal.placeholder_name')" v-model="callback.name">
                        <template v-if="$v.callback.name.$error">
                        <small class="form-text text-warning" v-if="!$v.callback.name.required">{{ $t('CommonForm.field_is_required') }}</small>
                        </template>
                    </div>
                    <div class="form-group">
                        <label for="inputphone1">{{ $t('CallbackModal.input_phone') }}</label>
                        <input type="phone" class="form-control" name="phone" id="inputphone1" :placeholder="$t('CallbackModal.placeholder_phone')" v-model="callback.phone">
                        <template v-if="$v.callback.phone.$error">
                        <small class="form-text text-warning" v-if="!$v.callback.phone.required">{{ $t('CommonForm.field_is_required') }}</small>
                        </template>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-zhako w-100" :disabled="false">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                            {{ $t('CallbackModal.button_submit') }}
                        </button>
                    </div>
                </form>

                <div v-if="screen === 'response'">
                    <p class="h5 text-center">{{ $t('CallbackModal.request_callback') }}</p>
                    <p class="text-center">{{ message }}</p>
                </div>

                </div>
            </div>
        </modal>
    </div>
</template>

<style lang="scss" scoped>
.callback-button {
    bottom: 30px;
    position: fixed;
    right: 30px;
    z-index: 9999;


    .container {
        background-color: #553DB4;
        color: white;
    }
}
</style>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    data: () => ({
        callback: {
            message: null,
            name: null,
            phone: null
        }
        ,screen: 'callback'
        ,spinnerVisible: false
        ,message: null
    }),
    validations: {
        callback: {
            message: { required },
            name: { required },
            phone: { required }
        }
    },
    methods: {
        show() {
            this.screen = "callback";
            this.$modal.show("ss-callback-modal");
        },
        hide() {
            this.$modal.hide("ss-callback-modal");
        },
        showSpinner() {
            this.spinnerVisible = true;
        },
        hideSpinner() {
            this.spinnerVisible = false;
        },

        onSubmitCallback() {
            this.$v.callback.$touch();
            if ( ! this.$v.callback.$invalid) {  
                this.showSpinner();
                setTimeout(() => {
                    this.$store.dispatch("SAVE_CALLBACK", this.callback)
                    .then(() => this.message = this.$t("CallbackModal.success_message"))
                    .catch(() => this.message = this.$t("CallbackModal.error_message"))
                    ;

                    this.hideSpinner();
                    this.screen = 'response';
                    this.callback = {};
                }, 3000);
            }
        }
    }
}
</script>