<template>
    <div class="ss--modal">
        <modal
            name="ss-login-modal"
            :adaptive="true"
            :scrollable="true"
            height="auto"
            width="500"
            >
            <div class="container">
                <button type="button" class="close text-white" aria-label="Close"
                    @click="hide"
                    >
                    <span aria-hidden="true">&times;</span>
                </button>

                <div class="pt-3 pb-3 pl-3 pr-3">

                <form @submit.prevent="onSubmitSignin" v-if="screen === 'signin'">
                    <div class="form-group">
                        <p class="h5 text-center">{{ $t('LoginModal.sign_in') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="inputEmail1">{{ $t('LoginModal.email') }}</label>
                        <input type="email" class="form-control" name="email" id="inputEmail1" aria-describedby="emailHelp" :placeholder="$t('LoginModal.email')" v-model="signin.email">
                    </div>
                    <div class="form-group">
                        <label for="inputPassword1">{{ $t('LoginModal.password') }}</label>
                        <input type="password" class="form-control" name="password" id="inputPassword1" :placeholder="$t('LoginModal.password')" v-model="signin.password">
                    </div>
                    <div class="form-group">
                        <p class="text-right"><a href="#" @click.prevent="showForgotPassword">{{ $t('LoginModal.forgot_password') }}</a></p>
                        <button type="submit" class="btn btn-zhako w-100" :disabled="false">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                            {{ $t('LoginModal.sign_in') }}
                        </button>
                        <small class="form-text"><a href="#" @click.prevent="showUserAgreement">{{ $t('LoginModal.by_continuing') }}</a></small>
                    </div>
                    <div class="form-group">
                        <p class="text-center">{{ $t('LoginModal.dont_have_account') }} <a href="#" @click.prevent="showSignupForm">{{ $t('LoginModal.sign_up') }}</a></p>
                    </div>
                </form>

                <form @submit.prevent="onSubmitSignup" v-if="screen === 'signup'" autocomplete="off">
                    <div class="form-group">
                        <p class="h5 text-center">{{ $t('LoginModal.sign_up') }}</p>
                    </div>
                    <div class="form-group">
                        <label for="inputEmail1">{{ $t('LoginModal.email') }}</label>
                        <input type="email" class="form-control" name="email" id="inputEmail1" aria-describedby="emailHelp" :placeholder="$t('LoginModal.email')" v-model.trim="signup.email">
                        <template v-if="$v.signup.email.$error">
                        <small class="form-text text-warning" v-if="!$v.signup.email.required">{{ $t('LoginModal.field_is_required') }}</small>
                        <small class="form-text text-warning" v-if="!$v.signup.email.email">{{ $t('LoginModal.enter_your_email') }}</small>
                        </template>
                    </div>
                    <div class="form-group">
                        <label for="inputPassword1">{{ $t('LoginModal.password') }}</label>
                        <input type="password" class="form-control" name="password" id="inputPassword1" :placeholder="$t('LoginModal.password')" v-model.trim="signup.password">
                        <template v-if="$v.signup.password.$error">
                        <small class="form-text text-warning" v-if="!$v.signup.password.required">{{ $t('LoginModal.enter_your_password') }}</small>
                        <small class="form-text text-warning" v-if="!$v.signup.password.min">{{ $t('LoginModal.at_least_8_characters') }}</small>
                        </template>
                    </div>
                    <div class="form-group">
                        <label for="inputConfirmPassword1">{{ $t('LoginModal.confirm_password') }}</label>
                        <input type="password" class="form-control" name="confirmPassword" id="inputConfirmPassword1" :placeholder="$t('LoginModal.confirm_password')" v-model.trim="signup.confirmPassword">
                        <template v-if="$v.signup.confirmPassword.$error">
                        <small class="form-text text-warning" v-if="!$v.signup.confirmPassword.required">{{ $t('LoginModal.type_your_password_again') }}</small>
                        <small class="form-text text-warning" v-if="!$v.signup.confirmPassword.sameAsPassword">{{ $t('LoginModal.passwords_must_match') }}</small>
                        </template>
                    </div>
                    <div class="form-group">
                        <label for="selectOwnership">{{ $t('LoginModal.type_of_ownership') }}</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="selectOwnership"><i class="fas" :class="ownershipIcon"></i></label>
                            </div>
                            <select class="custom-select" name="ownership" id="selectOwnership" v-model="signup.ownership">
                                <option v-for="o in ownerships" :value="o.id" :key="o.id">{{ $t('LoginModal.' + o.name) }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-zhako w-100">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                            {{ $t('LoginModal.sign_up') }}
                        </button>
                        <small class="form-text"><a href="#" @click.prevent="showUserAgreement">{{ $t('LoginModal.by_creating') }}</a></small>
                    </div>
                    <div class="form-group">
                        <p class="text-center">{{ $t('LoginModal.already_have_account') }} <a href="#" @click.prevent="showSigninForm">{{ $t('LoginModal.sign_in') }}</a></p>
                    </div>
                </form>

                <form @submit.prevent="onSubmitForgot" v-if="screen === 'forgot'">
                    <div class="form-group">
                        <p class="h5 text-center">{{ $t('LoginModal.reset_password') }}</p>
                        <p>{{ $t('LoginModal.forgot_message') }}</p>
                    </div>
                    <div class="form-group">
                        <input type="email" class="form-control" name="email" :placeholder="$t('LoginModal.forgot_email_placeholder')" v-model="forgot.email">
                    </div>
                    <div class="form-group">
                        <button type="submit" class="btn btn-zhako w-100" :disabled="false">
                            <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                            {{ $t('LoginModal.forgot_send_button') }}
                        </button>
                    </div>
                </form>

                <div v-if="screen === 'activate'">
                    <p class="h5 text-center">{{ $t('LoginModal.sign_up') }}</p>
                    <p>{{ $t('LoginModal.activate_message') }}</p>
                </div>

                </div>

            </div>
        </modal>
    </div>
</template>

<style lang="scss" scoped>
.ss--modal {
    background: yellow;

    .container {
        background-color: #553DB4;
        color: white;

        a {
            color: white;
            text-decoration: underline !important;
        }

        button.close {
            opacity: 1;
        }

        // .btn-primary {
        //     background-color: #FFAD00;
        //     border-color: #FFAD00;
        // }
        // .btn-primary:hover {
        //     background-color: #CCAD00;
        //     border-color: #CCAD00;
        // }
    }
}
</style>

<script>

import { required, minLength, email, numeric, sameAs } from "vuelidate/lib/validators";

export default {
    data: () => ({
        name: 'modal'
        ,screen: 'signin'
        ,spinnerVisible: false
        ,ownerships: [
            { id: 1, name: "individual",   icon: 'fa-user' },
            { id: 2, name: "entrepreneur", icon: 'fa-user-tie' },
            { id: 3, name: "company",      icon: 'fa-building' }
        ]
        ,signin: {
            email: null,
            password: null
        }
        ,signup: {
            email: null,
            password: null,
            confirmPassword: null,
            ownership: 1,
            invitedFrom: ''
        }
        ,forgot: {
            email: null
        }
    }),
    validations: {
        signup: {
            email: { required, email },
            password: { required, min: minLength(8) },
            confirmPassword: { required, sameAsPassword: sameAs('password') },
            ownership: { required, numeric },
            invitedFrom: {}
        }
    },
    props: {
        invited: String
    },
    mounted() {
        this.onCreate();
    },
    methods: {
        show() {
            this.$modal.show("ss-login-modal");
            this.onShow();
        },
        hide() {
            this.$modal.hide("ss-login-modal");
        },
        changeScreen(scr) {
            this.screen = scr;
        },
        showSigninForm() {
            this.changeScreen('signin');
        },
        showSignupForm() {
            this.changeScreen('signup');
        },
        showForgotPassword() {
            this.changeScreen('forgot');
        },
        showSpinner() {
            this.spinnerVisible = true;
        },
        hideSpinner() {
            this.spinnerVisible = false;
        },
        showUserAgreement() {
            this.hide();
            this.$router.push({name: 'user-agreement'});
        },

        onCreate() {

        },
        onShow() {
            this.showSigninForm();
            this.hideSpinner();
        },
        onSubmitSignin() {
            this.showSpinner();
            let data = {
                email: this.signin.email,
                password: this.signin.password
            }
            if (data.email || data.password) {
                this.$store.dispatch('LOGIN', data)
                    .then((result) => {
                        result.status === 200 && this.$toast.success("Вы вошли в систему");
                        this.hideSpinner();
                        this.hide();
                        this.signin = {};
                    })
                    .catch(() => {
                        this.$toast.error("Ошибка. Невозможно войти в систему");
                        this.hideSpinner();
                    })
                    ;
            }
            else {
                this.$toast.error("Ошибка. Введите email и пароль");
                this.hideSpinner();
            }
        },
        onSubmitSignup() {
            this.$v.signup.$touch();
            if (! this.$v.signup.$invalid) {
                this.showSpinner();
                this.signup.invitedFrom = this.invited;
                this.$store.dispatch("REGISTRATION", this.signup)
                    .then(() => {
                        this.hideSpinner();
                        this.changeScreen('activate');
                        this.signup = {};
                    })
                    .catch(e => e.message && this.$toast.error(e.message) && this.hideSpinner())
                    ;
            }
        },
        onSubmitForgot() {
            this.showSpinner();
            this.$store.dispatch("RESET_PASSWORD", this.forgot)
                .then(s => {
                    s.message && this.$toast.success(s.message);
                    this.hideSpinner();
                    this.hide();
                    this.forgot = {};
                })
                .catch(e => e.message && this.$toast.error(e.message) && this.hideSpinner())
                ;

        }
    },
    computed: {
        ownershipIcon() {
            return this.ownerships.reduce((a,c) => c.id == this.signup.ownership ? c : a).icon;
        }
    }
}
</script>