<template>
    <header class="sticky-top">
        <div class="header">
            <div class="main-header">
                <div class="header-area">
                    <div class="nav-top">
                        <div class="nav-top__left">
                            <ul>
                                <li>
                                    <a href="" @click.prevent="setLocale('kz')" :class="{active: this.$i18n.locale == 'kz'}">qaz</a>
                                </li>
                                <li>
                                    <a href="" @click.prevent="setLocale('ru')" :class="{active: this.$i18n.locale == 'ru'}">rus</a>
                                </li>
                            </ul>
                        </div>
                        <div class="nav-top__right">
                            <ul>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCDfTGV2XFMAHJsXB1WnPQ1g" target="_blank"><img src="@/assets/img/icons/ytube.svg" alt=""></a>
                                </li>

                                <li>
                                    <a href="https://t.me/EOZatamekenbot" target="_blank"><img src="@/assets/img/icons/qazbot.svg" alt=""></a>
                                </li>

                                <template v-if="!isAuth">
                                <li><a href="#" @click.prevent="$refs.login.show"> {{$t('navigation.login')}} </a></li>
								</template>

								<template v-else>
								<li 
                                    class="user-area"
                                    @click="showUser"
                                    v-on-clickaway="userAway"
                                >
                                    {{user.userDetail && user.userDetail.firstname || user.username}}
                                    <user-box v-if="userBlock"/>
                                </li>
                                <li 
                                    class="message-info" 
                                    @click="showBox"
                                    v-on-clickaway="away"
                                >
                                    <i class="far fa-bell"></i><span class="message-number"> {{COUNT}} </span>
                                    <message-box v-if="box" :messages="MESSAGES" :count="COUNT"/>
                                </li>
								<!-- <li><a href="#" @click.prevent="logout">Logout</a></li> -->
								</template>
                                <template v-if="hasRole('ROLE_ADMIN')">
								<li><a href="/admin.html">Admin</a></li>
                                </template>
                            </ul>
                            <feedback />
                        </div>
                    </div>

                    <login-modal ref="login" />

                    <nav class="nav-main navbar-expand-lg navbar-light">
                        <div class="main-width navbar">
                            <router-link class="navbar-brand"  to="/"><img src="@/assets/img/icons/main_logo.svg" alt=""></router-link>
                            <button class="navbar-toggler" style="border: none" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav mr-auto" :class="{slim:isSlimMenu()}">
                                    <template v-if="isSupplier()">
                                    <li><router-link to="/purchases"> {{$t('MainMenu.purchase')}} </router-link></li>
                                    <li><router-link to="/analytics">{{$t('MainMenu.analytics')}}</router-link></li>
                                    <li><router-link to="/packages"> {{$t('MainMenu.tariff')}} </router-link></li>
                                    <li><router-link :to="{ name: 'video' }"> {{ $t('MainMenu.video-tutorials') }} </router-link></li>
                                    <li><router-link to="/information"> {{ $t('MainMenu.regulations') }} </router-link></li>
                                    <li><router-link to="/platforms"> {{$t('MainMenu.enter_to_platforms')}} </router-link></li>
                                    <li><router-link :to="{ name: 'analytic-apk' }"> Для АПК РК </router-link></li>
                                    <li><router-link :to="{ name: 'notifications', query: {type: 101} }"> {{$t('cabinet.notifications')}} </router-link></li>
                                    <li><router-link :to="{ name: 'user-agreement' }"> {{ $t('MainMenu.agreement') }} </router-link></li>
                                    </template>
                                    <template v-else>
                                    <li><router-link to="/purchases"> {{$t('MainMenu.purchase')}} </router-link></li>
                                    <li><router-link to="/packages"> {{$t('MainMenu.tariff')}} </router-link></li>
                                    <li><router-link :to="{ name: 'video' }"> {{ $t('MainMenu.video-tutorials') }} </router-link></li>
                                    <li><router-link to="/analytics">{{$t('MainMenu.analytics')}}</router-link></li>
                                    <li><router-link to="/reestr"> {{$t('MainMenu.registr')}} </router-link></li>
                                    <li><router-link to="/about">{{$t('MainMenu.portal')}}</router-link></li>
                                    <li><router-link to="/information"> {{ $t('MainMenu.regulations') }} </router-link></li>
                                    <li><router-link to="/platforms"> {{$t('MainMenu.enter_to_platforms')}} </router-link></li>
                                    <li><router-link :to="{ name: 'analytic-apk' }"> Для АПК РК </router-link></li>
                                    <li><router-link :to="{ name: 'user-agreement' }"> {{ $t('MainMenu.agreement') }} </router-link></li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import Feedback from './Feedback.vue'
import LoginModal from './LoginModal'
import messageBox from './messageBox'
import userBox from './userBox'
import { mixin as clickaway } from "vue-clickaway"

import {mapActions, mapGetters} from 'vuex'


export default {
    name: 'app-navbar',
    mixins: [clickaway],
    data: () => ({
        box: false,
        userBlock: false
    }),
    components: {
        Feedback,
        LoginModal,
        messageBox,
        userBox,
    },
    computed: {
        ...mapGetters([
			'LANG',
			'isAuth',
            'user',
            'userRole',
            'COUNT',
            'MESSAGES'
        ]),
    },
    methods: {
        ...mapActions([
            'GET_LANGUAGE',
            'SET_LANGUAGE',
        ]),
        setLocale(locale) {
            this.$i18n.locale = locale;
            this.SET_LANGUAGE(locale)
        },
        showBox() {
            this.box = !this.box
        },
        showUser() {
            this.userBlock = !this.userBlock
        },
        away() {
            this.box = false
        },
        userAway() {
            this.userBlock = false
        },
        hasRole(role) {
            var r = this.user && this.user.userDRoleList 
                && this.user.userDRoleList.filter(el => el && el.dRoleId && el.dRoleId.code === role);
            return r && r.length > 0;
        },
        hasTariff() {
            return this.user && this.user.userDetail && this.user.userDetail.tariff;
        },
        isSupplier() {
            var tariff = this.hasTariff();
            return tariff && ['business', 'Tenders'].includes(tariff.code) || this.userRole === 'supplier';
        },
        isSlimMenu() {
            return this.$i18n.locale == 'kz';
        }
    },
    mounted() {
        this.GET_LANGUAGE()
    }
}
</script>

<style lang="scss">
.message-info {
    // margin-left: -10px;
    position: relative;
    .fa-bell {
        color: #fff;
        font-size: 22px;
    }
    .message-number {
        color: #fff;
        position: absolute;
        background: #FFAD00;
        font-size: 12px;
        top: -8px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.user-area{
    color: #FFAD00 !important;
    font-weight: 500;
    position: relative;
    cursor: pointer;
}

</style>