<template>
  <div id="app">
    <app-navbar v-show="!isStarter" />

    <main>
      <router-view/>
    </main>

    <callback-button v-show="!isStarter" ref="callbackButton" />
    <app-footer v-show="!isStarter" />
  </div>
</template>

<script>
import appNavbar from '@/components/app/appNavbar'
import appFooter from '@/components/app/appFooter'
import CallbackButton from "@/components/app/CallbackButton"
import {mapGetters} from 'vuex'

export default {
  name: 'App',
  data: () => ({
    loading: true
  }),
  components: {
    CallbackButton,
    appNavbar,
    appFooter
  },
  methods: {
    isAuth() {
      this.$store.dispatch('IS_AUTH');
    },
    isRoute(name) {
      return this.$route.name == name;
    },
    checkRole() {
      if ( this.userRole == null && !this.isStarter
          && !this.isRoute('registration') ) {
        this.$router.push({name: 'starter'});
      }

    }
  },
  computed: {
    ...mapGetters([
      'userRole'
    ]),
    isStarter() {
      return this.$route.name == 'starter'
    }
  },
  mounted() {
    this.isAuth();
    this.$store.dispatch("GET_USER");
    // this.$store.commit('SET_ROLE', null);

    // setTimeout(this.checkRole, 1000);
    this.$root.$on('callback-show', () => {
      this.$refs.callbackButton.show();
    });
  },
  beforeUpdate() {
    this.isAuth();
  },
}
</script>


<style lang="scss">
@import 'assets/libs/bootstrap/css/bootstrap.min.css';
@import 'assets/style/style.scss';

</style>
