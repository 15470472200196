<template>
    <div class="user-box">
        <ul>
            <li><router-link :to="{ name: 'profile' }"> {{$t("cabinet.organization")}} </router-link></li>
            <li><router-link :to="{ name: 'procurement' }"> {{$t("cabinet.procurement_notice")}} </router-link></li>
            <li><router-link :to="{ name: 'notifications' }"> {{$t("cabinet.notifications")}} </router-link></li>
            <li><router-link :to="{ name: 'appeals' }"> {{$t("cabinet.appeals")}} </router-link></li>
            <li><router-link :to="{ name: 'orders' }"> {{$t("cabinet.orders")}} </router-link></li>
            <li><router-link :to="{ name: 'invitations' }"> {{$t("cabinet.invitation")}} </router-link></li>
            <li><router-link :to="{ name: 'account-documents' }"> {{$t("cabinet.documents")}} </router-link></li>
            <!-- <li><router-link :to="{ name: 'search-templates' }"> {{$t("cabinet.search_templates")}} </router-link></li> -->
            <li>
                <a @click="logout"> <i class="fas fa-sign-out-alt"></i>Выйти </a>
            </li>
        </ul>
    </div>
</template>

<script>

import {mapActions} from 'vuex'

    export default {
        methods: {
            ...mapActions([
                'LOGOUT'
            ]),
            logout() {
                this.LOGOUT()
                this.$toast('Вы вышли из системы')
                this.$router.push('/');
            }
        }
    }
</script>

<style lang="scss" scoped>
.user-box {
    text-align: left;
    width: 250px;
    border: 1px solid #ccc;
    z-index: 5;
    background: rgb(248, 248, 248);
    position: absolute;
    top: 50px;
    border-radius: 5px;
    left: -100px;
    ul {
        padding: 0;
        li {
            margin-right: 0;
            color: #000;
            a {
                display: block;
                padding: 10px 0 10px 15px;
                color: #333;
                border-radius: 5px;
                transition: .3s;
                &:hover {
                    & {
                        background: #fff;
                    }
                }
                i {
                    margin-right: 15px;
                }
            }
        }
    }
}
</style>