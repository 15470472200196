<template>
        <footer>
            <div class="footer">
                <section class="footer__first">
                    <div class="main-width">
                        <div class="row">
                            <div class="col-12 col-md-2 col-sm-4">
                                <div class="top-footer">
                                    <h3>НАВИГАЦИЯ</h3>
                                    <p><router-link :to="{ name: 'analytics' }">{{$t('navigation.analytics')}}</router-link></p>
                                    <p><router-link :to="{ name: 'reestr' }">{{ $t("navigation.reestr") }}</router-link></p>
                                    <p><router-link :to="{ name: 'packages' }"> {{$t('navigation.services')}} </router-link></p>
                                    <p><router-link :to="{ name: 'about' }"> {{$t('navigation.portal')}} </router-link></p>
                                </div>
                            </div>
                            <div class="col-12 col-md-2 col-sm-4">
                                <div class="top-footer">
                                    <h3>{{$t('navigation.purchase')}}</h3>
                                    <p><router-link :to="{ name: 'plans' }"> {{$t('navigation.purchase_plan')}}</router-link></p>
                                    <p><router-link :to="{ name: 'tenders' }">{{$t('navigation.tender')}}</router-link></p>
                                    <p><router-link :to="{ name: 'lots' }">{{$t('navigation.lot')}}</router-link></p>
                                    <p><router-link :to="{ name: 'contracts' }">{{$t('navigation.contracts')}}</router-link></p>
                                    <p><router-link :to="{ name: 'favourite' }">{{$t('navigation.favourite')}}</router-link></p>
                                </div>
                            </div>
                            <div class="col-12 col-md-2 col-sm-4">
                                <div class="top-footer">
                                    <h3>{{$t('navigation.info')}}</h3>
                                    <p><router-link :to="{ name: 'documents' }"> {{$t('navigation.trule')}} </router-link></p>
                                    <p><router-link :to="{ name: 'videos' }"> {{$t('navigation.instruction')}} </router-link></p>
                                    <p><router-link :to="{ name: 'user-agreement' }"> {{$t('Information.UserAgreement')}} </router-link></p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 col-sm-4">
                                <div class="top-footer">
                                    <h3> {{$t('navigation.plinks')}} </h3>
                                    <p><a target="_blank" href="https://atameken.kz/">НПП РК «Атамекен»</a></p>
                                    <p><a target="_blank" href="https://goszakup.gov.kz/"> {{$t('navigation.goszakup')}}</a></p>
                                    <p><a target="_blank" href="http://reestr.nadloc.kz/kz/page/static/default">NadLoc</a></p>
                                    <p><a target="_blank" href="https://www.baiterek.gov.kz/ru/">Байтерек</a></p>
                                    <p><a target="_blank" href="https://www.skc.kz/">ТОО Самрук-Казына</a></p>
<!--                                  fix for task from 12.01-->
<!--                                    <p><a target="_blank" href="https://kazagro.kz/">КазАгро</a></p>-->
                                </div>
                            </div>
                
                            <div class="col-12 col-md-3 col-sm-4">
                                <div class="top-footer text__right">
                                    <h3>КОНТАКТЫ</h3>
                                    <p><a target="_blank" href="http://cmer.kz/o-nas/"> <i18n path="footer.company" > <br place="break">  </i18n> </a></p>
                                    <p><a href="tel:+77021111433"> +7(702) 111 14 33</a></p>
                                    <p><a href="mailto:info@cmer.kz">info@cmer.kz</a></p>
                                    <p><a href="https://www.youtube.com/channel/UCDfTGV2XFMAHJsXB1WnPQ1g" target="_blank"><img src="@/assets/img/icons/footer_ytube.png" class="mr-2" alt=""></a>  
                                    <a href="https://t.me/EOZatamekenbot" target="_blank"><img src="@/assets/img/icons/footer_bot.svg" alt=""></a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="footer__second">
                    <div class="main-width">
                        <div class="footer__area">
                            <p>© {{getYear}} {{$t('navigation.eoz')}}. {{$t('navigation.copyright')}}</p>
                            <!-- <p>Разработка компании <a href="https://geometry.kz/" target="_blank"> &nbsp; Geometry</a></p> -->
                        </div>
                    </div>
                </section>
            </div>
        </footer>
</template>

<script>
export default {
    name: 'app-footer',
    computed: {
        getYear() {
            return new Date().getFullYear()
        }
    }
}
</script>