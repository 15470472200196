import axios from "axios";
axios.defaults.withCredentials = true
// axios.defaults.baseURL = "https://10.20.40.87";

// const headers = {
//   "Content-Type": "application/json",
// }

export default {
  state: {
    count: 0,
    messages: [],
  },
  mutations: {
    SET_COUNT(state, payload) {
      state.count = payload
    },
    SET_MESSAGES(state, payload) {
      state.messages = payload
    },
  },
  actions: {
    GET_COUNT({ commit }) {
      axios
        .get("/api/notification/get/unread/count")
        .then((res) => {
          commit("SET_COUNT", res.data.count);
        });
    },
    GET_MESSAGES({ commit }) {
      axios.get("/api/notification/get/last").then((res) => {
        // console.log(res.data.content)
        commit("SET_MESSAGES", res.data.content)
      });
    },
  },
  getters: {
    COUNT: (state) => state.count,
    MESSAGES: state => state.messages
  },
};
